import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import logo from '../../images/LearningMate-logo.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { googleLogout } from '@react-oauth/google';
const Header = () => {
    const navigate = useNavigate();
    const [ user, setUserProfile ] = useState(null);
    
    useEffect(
        () => {
            setUser();
        }, []);

    const navigateToQuestionAnswer = () => {
        navigate('/question-answer');
    };

    const setUser= () => {
        AsyncStorage.getItem('profile').then((profile) => {
            if(profile){
                try {
                    setUserProfile(JSON.parse(profile));
                  }
                  catch(err) {
                    AsyncStorage.removeItem('profile');
                  }
                
            }
        });
    };


        const logOut = () => {
            googleLogout();
            AsyncStorage.removeItem('profile');
            AsyncStorage.setItem('status','logout');
            navigate('/login');
        };
    return (
        <nav className=" custom_navbar">
            <div className="fullArea" style={{ "backgroundColor": "transparent;min-height: 65px" }}>
                <div className="top-part" aria-label="Leader area">
                    <img src={logo} alt="LearningMate" />
                    <div className="tools_dropdown">
                        <button className="d-btn" type="button" aria-expanded="false" aria-haspopup="true">More
                            <span className="material-symbols-rounded">expand_more</span>
                        </button>
                        <div className="d-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/question-answer" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">forum</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Question & Answer Generation</h5>
                                                        <p className="card-text" onClick={navigateToQuestionAnswer}>Generate Question and answer</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/image-alt-text" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-sharp">add_photo_alternate</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Image Alt text</h5>
                                                        <p className="card-text">Generate Image Alt text</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/contextual-alt-text" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-sharp">text_fields</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Contextual Alt text</h5>
                                                        <p className="card-text">Create Contextual Alt text</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/summarization" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-sharp">subtitles</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Summarization</h5>
                                                        <p className="card-text">Generate Summary</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/suggestive-Keyword" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">spellcheck</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Suggestive Keywords</h5>
                                                        <p className="card-text">Generate Suggestive Keywords</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/grading-assistant" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">grading</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Grading Assistant</h5>
                                                        <p className="card-text">Create Auto Grading Answers</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/content-curation" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded" style={{ "transform": "rotateX(180deg)" }}>content_copy</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Content Curation</h5>
                                                        <p className="card-text">Create Content</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/assessment" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">quiz</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Assessment</h5>
                                                        <p className="card-text">Create Assessment</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/flashcard" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">style</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Flashcard</h5>
                                                        <p className="card-text">Create Flashcard</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/ai-assistant-learner-chatbot" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">chat</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">AI Chatbot in LMS</h5>
                                                        <p className="card-text">Start using Chatbot and AI in your LMS</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/ai-assistant-curriculum" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">local_library</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Curriculum Design</h5>
                                                        <p className="card-text">Create Curriculum Design</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/ai-assistant-question-answer-generator" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">question_mark</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Quiz Generator in LMS</h5>
                                                        <p className="card-text">Generate quizzes In LMS</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href="/logeneration" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">local_library</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">LO Generator</h5>
                                                        <p className="card-text">Generate Learning Objective</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href={process.env.REACT_APP_OCR_URL} target="_blank" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">local_library</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">OCR</h5>
                                                        <p className="card-text">OCR</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="card" >
                                            <a href={process.env.REACT_APP_COPY_EDIT_WORKFLOW} target="_blank" className="menu-btn-card">
                                                <div className="card-body d-flex">
                                                    <span className="material-symbols-rounded">local_library</span>
                                                    <div className="card-element">
                                                        <h5 className="card-title">Copy Edit Workflow</h5>
                                                        <p className="card-text">Copy Edit Workflow</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div>
                    <div className="userProfileDiv">Welcome&nbsp;
                    {
                        user?
                        <span className="userProfile">{user.name}! <a href="javascript:;" className="loginLogout" onClick={logOut} >Logout</a></span>:
                        <span className="userProfile">Guest! <a href="/login" className="loginLogout">Login</a></span> 
                    }
                    </div>
                        <a href={process.env.REACT_APP_USER_GUIDE_URL} target="_blank" className="btn_contactUs">User Guide</a>
                        <a href={process.env.REACT_APP_AI_WORKBENCH_URL} target="_blank" className="btn_contactUs">AI WORKBENCH</a>
                        <a href={process.env.REACT_APP_SUGGESTIONS} target="_blank" className="btn_contactUs">SUGGESTIONS</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;