import { React, useState } from 'react';
import '../Login/login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Header/header";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [type, setButtonType] = useState('All');

  const navigateToUrl = (url) => {
    navigate(url);
  };

  const resetTabs = async (type) => {
    setButtonType(type);
  }


  return (
    <div>
      <Header />
      <main className="tabcontents">
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true" onClick={() => resetTabs('All')}>All</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-teacher-tab" data-bs-toggle="pill" data-bs-target="#pills-teacher" type="button" role="tab" aria-controls="pills-teacher" aria-selected="false" onClick={() => resetTabs('Teacher')}>Teacher</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-student-tab" data-bs-toggle="pill" data-bs-target="#pills-student" type="button" role="tab" aria-controls="pills-student" aria-selected="false" onClick={() => resetTabs('Student/Learner')}>Student/Learner</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="false" onClick={() => resetTabs('Content Development')}>Content Development</button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-admin-tab" data-bs-toggle="pill" data-bs-target="#pills-admin" type="button" role="tab" aria-controls="pills-admin" aria-selected="false" onClick={() => resetTabs('Admin')}>Admin</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link reset_btn" id="pills-reset-tab" data-bs-toggle="pill" data-bs-target="#pills-reset" type="button" role="tab" aria-controls="pills-reset" aria-selected="false" onClick={() => resetTabs('Reset')}>Reset</button>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="tab-content-area" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
              <div className="row">

                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">forum</span>
                        <div className="card-body">
                          <h5 className="card-title">Question & Answer Generation</h5>
                          <div className="card-element">
                            <p className="card-text">Generate Question and answer</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/question-answer')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-sharp">add_photo_alternate</span>
                        <div className="card-body">
                          <h5 className="card-title">Image Alt text</h5>
                          <div className="card-element">
                            <p className="card-text">Generate Image Alt text</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/image-alt-text')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-sharp">text_fields</span>
                        <div className="card-body">
                          <h5 className="card-title">Contextual Alt text</h5>
                          <div className="card-element">
                            <p className="card-text">Create Contextual Alt text</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/contextual-alt-text')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Student/Learner' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-sharp">subtitles</span>
                        <div className="card-body">
                          <h5 className="card-title">Summarization</h5>
                          <div className="card-element">
                            <p className="card-text">Generate Summary</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/summarization')} >chevron_right</span>
                          </div>

                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">spellcheck</span>
                        <div className="card-body">
                          <h5 className="card-title">Suggestive Keywords</h5>
                          <div className="card-element">
                            <p className="card-text">Generate Suggestive Keywords</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/suggestive-keyword')}>chevron_right</span>
                          </div>

                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">grading</span>
                        <div className="card-body">
                          <h5 className="card-title">Grading Assistant</h5>
                          <div className="card-element">
                            <p className="card-text">Create Auto Grading Answers</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/grading-assistant')}>chevron_right</span>
                          </div>

                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded" style={{ "transform": "rotateX(180deg)" }}>content_copy</span>
                        <div className="card-body">
                          <h5 className="card-title">Content Curation</h5>
                          <div className="card-element">
                            <p className="card-text">Create Content</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/content-curation')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">quiz</span>
                        <div className="card-body">
                          <h5 className="card-title">Assessment</h5>
                          <div className="card-element">
                            <p className="card-text">Create Assessment</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/assessment')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">style</span>
                        <div className="card-body">
                          <h5 className="card-title">Flashcard</h5>
                          <div className="card-element">
                            <p className="card-text">Create Flashcard</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/flashcard')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }


                {
                  type === 'Student/Learner' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">chat</span>
                        <div className="card-body">
                          <h5 className="card-title">AI Chatbot in LMS</h5>
                          <div className="card-element">
                            <p className="card-text">Start using Chatbot and AI in your LMS</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/ai-assistant-learner-chatbot')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">local_library</span>
                        <div className="card-body">
                          <h5 className="card-title">Curriculum Desiger</h5>
                          <div className="card-element">
                            <p className="card-text">Create Curriculum Desiger</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/ai-assistant-curriculum')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Teacher' || type === 'All' || type === 'Content Development' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">question_mark</span>
                        <div className="card-body">
                          <h5 className="card-title">Quiz Generator in LMS</h5>
                          <div className="card-element">
                            <p className="card-text">Generate quizzes In LMS</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/ai-assistant-question-answer-generator')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">local_library</span>
                        <div className="card-body">
                          <h5 className="card-title">LO Generator</h5>
                          <div className="card-element">
                            <p className="card-text">LO Generation</p>
                            <span className="material-symbols-rounded" onClick={() => navigateToUrl('/logeneration')}>chevron_right</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">local_library</span>
                        <div className="card-body">
                          <h5 className="card-title">OCR</h5>
                          <div className="card-element">
                            <p className="card-text">OCR</p>
                            <a className="menu-btn-card" href={process.env.REACT_APP_OCR_URL} target="_blank"><span className="material-symbols-rounded">chevron_right</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">local_library</span>
                        <div className="card-body">
                          <h5 className="card-title">Copy Edit Workflow</h5>
                          <div className="card-element">
                            <p className="card-text">Copy Edit Workflow</p>
                            <a className="menu-btn-card" href={process.env.REACT_APP_COPY_EDIT_WORKFLOW} target="_blank"><span className="material-symbols-rounded">chevron_right</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {
                  type === 'Content Development' || type === 'All' ?
                    <div className="col-md-6">
                      <div className="card" >
                        <span className="material-symbols-rounded">local_library</span>
                        <div className="card-body">
                          <h5 className="card-title">Summarize Workflow</h5>
                          <div className="card-element">
                            <p className="card-text">Summarize Workflow</p>
                            <a className="menu-btn-card" href={process.env.REACT_APP_SUMMARIZE_WORKFLOW} target="_blank"><span className="material-symbols-rounded">chevron_right</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    : ""
                }


              </div>
            </div>

            {/* <div className="tab-pane fade" id="pills-teacher" role="tabpanel" aria-labelledby="pills-teacher-tab">Teacher content</div>
            <div className="tab-pane fade" id="pills-student" role="tabpanel" aria-labelledby="pills-stuent-tab">Student/Learner content</div>
            <div className="tab-pane fade" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">Content Development</div>
            <div className="tab-pane fade" id="pills-admin" role="tabpanel" aria-labelledby="pills-admin-tab">Content Development</div>
            <div className="tab-pane fade" id="pills-reset" role="tabpanel" aria-labelledby="pills-reset-tab">reset content</div> */}
          </div>
        </div>



        {/* <!-- <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab"> 
                                all tab content
            
                            </div>
                            <div className="tab-pane fade" id="pills-teacher" role="tabpanel" aria-labelledby="pills-teacher-tab">Teacher content</div>
                            <div className="tab-pane fade" id="pills-student" role="tabpanel" aria-labelledby="pills-stuent-tab">Student/Learner content</div>
                            <div className="tab-pane fade" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">Content Development</div>
                            <div className="tab-pane fade" id="pills-admin" role="tabpanel" aria-labelledby="pills-admin-tab">Content Development</div>
                            <div className="tab-pane fade" id="pills-reset" role="tabpanel" aria-labelledby="pills-reset-tab">reset content</div>
                        </div>                         --> */}




        {/* <File_Uploader /> */}


      </main>
    </div>
  )
}

export default Home;