import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import axios from 'axios';
import Header from "../Header/header";
import Loader from "../Loader"
import File_Uploader from "../FileUpload/fileupload";
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";

const Summarization = () => {
    const [text, setText] = useState('')
    const [resList, setResList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');


    //Reset data
    const resetData = async () => {
        setText('');
        setResList([]);
        setLoading(false);
    }

    const uploadedTextData = (text1) => {
        // setReadOnly(true);
        setText(text1);
    }

    const formData = {
        text: text,
    }
    const postData = async () => {
        if (formData.text === '') {
            setMode("error");
            setMessage("Please type or paste or select a file for processing.");
        } else {
            try {
                setLoading(true);
                await axios.post(process.env.REACT_APP_SUMRIZATION_URL, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json, text/plain, /'
                    },
                }).then((response) => {
                    setResList(response.data.data);
                    setLoading(false);
                    if (response.data.data && response.data.data.length > 0){
                        setMode("success");
                        setMessage("Please scroll down to see the generated result.");
                    }else{
                        setMode("error");
                        setMessage(process.env.REACT_APP_NO_DATA);
                    }
                });

            } catch (error) {
                setLoading(false);
                setMode("error");
                setMessage("Error while process the request.");
            }
        }
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">subtitles</span>
                            <h1>Summarization</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Summarize any text with ease.</p>
                        </div>
                    </div>
                    {loading ? <Loader /> : ""}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="question_drop">Add Content</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea maxLength="3000" className="form-control" value={text} placeholder="Paste your Text here" id="question_drop" style={{ "minHeight": "150px" }} onChange={(e) => setText(e.target.value)}>
                                        </textarea>
                                        <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                        <div className="lines"><span>OR</span></div>
                                        <File_Uploader onFileUpload={uploadedTextData} />
                                    </div>
                                </div>
                                {/* <div className="inputBox">
                                    <label >Max summary word count</label>
                                    <div className="rightFormArea question-no" >
                                        <div className="value-button decrease" id="word_count_decrease" onClick={decreaseValue} value="setValue">
                                            <span className="material-symbols-rounded">remove</span>
                                        </div>
                                        <input type="number" id="number" value={value} onChange={(e) => setValue(e.target.value)} />
                                        <div className="value-button increase" id="word_count_increase" onClick={increaseValue} value="setValue">
                                            <span className="material-symbols-rounded">add</span>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Submit</button>
                                </div>
                                {
                                    resList?.summarized_content ?
                                        <div className="contents-part">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="wrapper-ai-case-content summarization_area" style={{ "minHeight": "280px" }} >
                                                            <div className="inputBox">
                                                                <p>Summarized Content</p>

                                                                <div className="summarized-content"  >

                                                                    <span>{resList.summarized_content}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>


                        </div>
                    </div>
                </div>
            </main>
            <Toaster message={message} mode={mode}/>
        </div>
    )
}

export default Summarization;