import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import axios from 'axios';
import Header from "../Header/header";
import Back from "../Back/back";
import Loader from "../Loader";
import 'react-toastify/dist/ReactToastify.css';
import File_Uploader from '../FileUpload/fileupload';
import Toaster from "../Toaster/Toaster";
import { json } from "react-router-dom";
// var createReactClass = require('create-react-class');
// import '.../images/images'

const QuestionAnswer = () => {
    const [text, setText] = useState('')
    const [value, setValue] = useState(0);
    const [editData, setEditData] = useState(null);
    const [resList, setResList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([])
    const [selected, setSelected] = useState("True/False");
    const [showResult, setShowResult] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [mode, setMode] = useState('success');

    const questionTypes = [
        { id: 'True/False', label: 'first', uri: process.env.REACT_APP_TRUEFALSE_URL },
        { id: 'Fill in the blanks', label: 'second', uri: process.env.REACT_APP_FILL_IN_THE_BLANK_URL },
        { id: 'Single Choice', label: 'third', uri: process.env.REACT_APP_SINGLE_CHOICE_URL },
        { id: 'Multiple Choice', label: 'fourth', uri: process.env.REACT_APP_MULTIPAL_CHOICE_URL },
    ];


    const handleChange = (event) => {
        setText('');
        setValue(0);
        setEditData(null);
        setResList([]);
        setLoading(false);
        setDataList([])
        setShowResult(false);
        setSelected(event.target.value);
    };

    //function of No of question
    const increaseValue = () => {
        if(value<5){
            setValue(value + 1);
        }
    }

    const decreaseValue = () => {
        if(value>0){
            setValue(value - 1);
        }
    }
    const questionDisplay = dataList.slice(0, value);

    const uploadedTextData = (text1) => {
        setText(text1);
    }

    const formData = {
        text: text,
        number_of_questions: value
    }
    const callApi = async (url) => {
        let data = [];
        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, /'
                },
            }).then((response) => {
                data = response.data.data;
            });
        } catch (error) {
            console.log('error', error);
        }

        return data;
    }

    const resetData = async () => {
        setText('');
        setValue(0);
        setEditData(null);
        setResList([]);
        setLoading(false);
        setDataList([])
        setSelected("True/False");
        setShowResult(false);
    }



    const postData2 = async () => {
        try {
            let validForm = true;
            if (formData.text === '') {
                validForm = false;
                setMode("error");
                setMessage("Please type or paste or select a file for processing.");
            }
            if (formData.number_of_questions === 0) {
                validForm = false;
                setMode("error");
                setMessage("Please enter the number of questions you want to generate.");
            }
            if (validForm) {
                setResList([]);
                setDataList([]);
                setShowResult(false);
                setLoading(true);
                const qType = questionTypes.find((type) => type.id === selected);
                const response = await callApi(qType.uri);
                if(selected === 'Single Choice'){
                    for(let i=0; i<response.length-1;i++){
                        response[i].options = shuffle(response[i].options);
                    }
                }
                setResList(response);
                setDataList(response);
                setLoading(false);
                if (response && response.length > 0) {
                    setMode("success");
                    setMessage("Please scroll down to see the generated result.");
                    setShowResult(true);
                } else {
                    setMode("error");
                    setMessage(process.env.REACT_APP_NO_DATA);
                }
            }

        } catch (error) {
            console.log('error', error);
        }
    }

    const shuffle = (array) => {
        const shuffled = array.slice(); 
        let currentIndex = shuffled.length; 
        let temporaryValue, randomIndex; 
        while (currentIndex !== 0) { 
          randomIndex = Math.floor(Math.random() * currentIndex); 
          currentIndex -= 1; 
          temporaryValue = shuffled[currentIndex]; 
          shuffled[currentIndex] = shuffled[randomIndex]; 
          shuffled[randomIndex] = temporaryValue; 
        }
        return shuffled; 
      }; 

    const publishClick = () => {
        setMode("success");
        setMessage("Content published.");
    }


    const setSingleChoiceChange = (e) => {
        editData.answer = e.target.value;
        document.getElementById('answerField').value = e.target.value;
    }

    const setSingleChoiceOptionChange = (e) => {
        let index = e.target.id.replace('option','');
        editData.options[index]=e.target.value;
    }

    const handleEditClick = (index) => {
        setSelectedIndex(index);
        const updatedEditData = resList[index];
        if(updatedEditData!=undefined){
            if(document.getElementById('answerField')!==undefined && selected === "Single Choice"){
                document.getElementById('answerField').value = updatedEditData.answer;
            }
        }
        setEditData(JSON.parse(JSON.stringify(updatedEditData)));
    };

    const handleSaveClick = () => {
        resList[selectedIndex]=JSON.parse(JSON.stringify(editData));
        setEditData(JSON.parse(JSON.stringify(resList)));
    };

    const handleQuestionChange = event => {
        editData.question = event.target.value;
        setEditData(JSON.parse(JSON.stringify(editData)));
    };

    const textChange = event => {
        editData.question = event.target.value;
        setEditData(JSON.parse(JSON.stringify(editData)));
    };

    const handleAnswerChange = event => {
        if(selected === 'Multiple Choice'){
            editData.correct_answers = [];
            event.target.value.split(',').forEach(element => {
                editData.correct_answers.push(element.trimLeft())
            });
            
        }else{
            editData.answer = event.target.value;
        }
       
        setEditData(JSON.parse(JSON.stringify(editData)));
    };
    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-rounded">forum</span>
                            <h1>Question & Answer Generation</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Question and answer generator is a type of artificial intelligence technology that can generate questions and answers automatically.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="">
                                <div className="accordion content-input-area" id="accordionExample">
                                    <div className="accordion-item">

                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Input Data
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <form>
                                                    <div className="inputBox  ">
                                                        <label htmlFor="question_drop">Type of Question</label>
                                                        <div className=" multipleSelection">

                                                            <select className="form-select mb-3" aria-label="" id="question_drop" onChange={handleChange} >
                                                                {questionTypes.map((option) => {
                                                                    return <option key={option.id} value={option.id}>{option.id}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="inputBox">
                                                        <label htmlFor="question_drop">No of Questions</label>
                                                        <div className="rightFormArea question-no">
                                                            <div className="value-button decrease" id="decrease" onClick={decreaseValue} value="setValue">
                                                                <span className="material-symbols-rounded">remove</span>
                                                            </div>
                                                            <input type="number" id="number" value={value} onChange={(e) => setValue(e.target.value)} />
                                                            <div className="value-button increase" id="increase" onClick={increaseValue} value="setValue">
                                                                <span className="material-symbols-rounded">add</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inputBox">
                                                        <label htmlFor="question_drop">Add Content</label>
                                                        <div style={{ "position": "relative" }}>
                                                            <textarea className="form-control" placeholder="Paste your Text here" id="text" style={{ "minHeight": "150px" }} value={text} onChange={(e) => setText(e.target.value)} maxLength="3000"></textarea>
                                                            <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                                            <div className="lines"><span>OR</span></div>
                                                            <File_Uploader onFileUpload={uploadedTextData} />
                                                        </div>
                                                    </div>
                                                    <div className="inputBox d-flex justify-content-end BtnBox">
                                                        <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                                        <button type="button" className="btn btn-primary btn_generate" onClick={postData2}>Generate Questions</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        showResult ?
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">

                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        AI Generated Result
                                                    </button>

                                                </h2>
                                                <div>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">

                                                        <div className="accordion-body question-list">
                                                            {/* <div className="inputBox d-flex justify-content-start BtnBox" style={{ "marginTop": "0" }}>
                                                                {
                                                                    <button type="button" key="button1" className="btn btn-primary btn_ai-questions" value={selected} onClick={() => handleSelectedButton(selected)} >{selected}</button>
                                                                }
                                                            </div> */}
                                                            {
                                                                questionDisplay.map((data, index) => (
                                                                    // dataList.map((data, index) => (
                                                                    <div className="accordion-child" key={`accordion${index}`}>
                                                                        <div className="accordion " id={`accordionQuestion${index}`}>
                                                                            <div className="accordion-item">
                                                                                <h3 className="accordion-header" id={`headingQst${index}`}>
                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" key={`accordion-button${index}`} data-bs-target={`#collapseQst${index}`} aria-expanded="true" aria-controls={`collapseQst${index}`}>
                                                                                        <span className="question-part">{`Question ${index + 1}: ${data.question}`}</span>

                                                                                    </button>
                                                                                    <button type="button" className="icon_Edit" data-bs-toggle="modal" key={`icon_Edit${index}`} data-bs-target="#exampleModal" onClick={() => handleEditClick(index)}>
                                                                                        <span className="material-symbols-rounded ">edit</span>
                                                                                    </button>
                                                                                </h3>
                                                                                {
                                                                                    selected === "Single Choice" ?
                                                                                        <div className="singleChoice" id={`collapseQst${index}`}>
                                                                                            {
                                                                                                data.options.map((option, index1) => (
                                                                                                    <div class="d-flex mb-2">
                                                                                                        <input type="radio" class="me-3" key={`option${index}${index1}`} id={`option${index}${index1}`} name={`option${index}`} checked={option === data.answer} />
                                                                                                        <label class="txt-card" htmlFor={`option${index}${index1}`}>{option}</label>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>

                                                                                        : ""
                                                                                }
                                                                                <div id={`collapseQst${index}`} className="accordion-collapse collapse show" aria-labelledby={`headingQst${index}`} data-bs-parent={`#accordionQuestion${index}`}>
                                                                                    <div className="accordion-body">
                                                                                        {
                                                                                            selected === "Multiple Choice" ? <p>Answers: {data.correct_answers?.join(', ')}</p>
                                                                                                : <p>Answer: {data.answer}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            <div className="inputBox d-flex justify-content-end BtnBox">
                                                                <button type="button" className="btn btn-primary btn_all btn_validate" onClick={publishClick}>Publish</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {
                loading ? <Loader /> : ""
            }
            <div className="modal fade question-edit-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header visually-hidden">
                            <h5 className="modal-title " id="exampleModalLabel">Questions Edit </h5>
                            {/* <!-- <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> --> */}
                        </div>

                        <div className="modal-body">
                            <div className="input-field">
                                <label htmlFor="questionField">Question</label>
                                <input className="form-control" type="text" aria-label="Edit Question field" onChange={handleQuestionChange} value={editData?.question} name="questionField" id="questionField" />
                            </div>
                            {
                                selected === "Single Choice" ?
                                    <div className="singleChoice" id={`collapseQstEdit`}>
                                        {
                                            editData?.options?.map((option, index1) => (
                                                <div class="d-flex mb-2">
                                                  <input type="radio" onChange={setSingleChoiceChange} value={option} class="me-3" key={`editOption${index1}`} id={`editOption${index1}`} name="editOption"  defaultChecked={option === editData.answer} />
                                                    {/* <label class="txt-card" htmlFor={`editOption${index1}`}>{option}</label> */}
                                                    <input type="text" className="form-control" onChange={setSingleChoiceOptionChange} defaultValue={option} key={`option${index1}`} id={`option${index1}`}  />

                                                </div>
                                            ))
                                        }
                                    </div>

                                    : ""
                            }
                            <div className="input-field">
                                <label htmlFor="answerField">Answer</label>
                                {selected === "Multiple Choice" ?
                                    (<input className="form-control" type="text" aria-label="Edit Answer field" onChange={handleAnswerChange} value={editData?.correct_answers?.join(', ')} name="answerField" id="answerField" />) :
                                    (<input className="form-control" type="text" aria-label="Edit Answer field" onChange={handleAnswerChange} value={editData?.answer} name="answerField" id="answerField" />)
                                }</div>

                        </div>
                        <div className="modal-footer BtnBox">
                            <button type="button" className="btn btn_all" style={{'background':'gray'}} data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary btn_all btn_save" data-bs-dismiss="modal" onClick={handleSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster message={message} mode={mode} />
        </div>


    )
}

export default QuestionAnswer;
